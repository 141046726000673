var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          tooltipMsg: "pmanualforemail",
          showActionDelete: _vm.showDelete,
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openDeleteModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v("\n\t\t" + _vm._s(_vm.$t("detailofemail")) + "\n\t\t"),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("id")) +
                        " " +
                        _vm._s(_vm.model.PemailId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "customActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDuplicate,
                        expression: "showDuplicate",
                      },
                    ],
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("duplicateemail"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.duplicate()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2033672342
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }