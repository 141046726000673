import i18n from '../../services/lang';
import enums from '@/services/helpers/enums';

export function handleTextboxVisibility(modelCountryId, model) {
	const schemaUpdate = {
		PemailSubjectSk: { visible: false },
		PemailSubjectCz: { visible: false },
		PemailSubjectPl: { visible: false },
		PemailSubjectEn: { visible: false },
		PemailSubjectDe: { visible: false },
		PemailSubjectHu: { visible: false },
		PemailSubjectRu: { visible: false },
		PemailTextSk: { visible: false },
		PemailTextCz: { visible: false },
		PemailTextPl: { visible: false },
		PemailTextEn: { visible: false },
		PemailTextDe: { visible: false },
		PemailTextHu: { visible: false },
		PemailTextRu: { visible: false },
	};

	switch (modelCountryId) {
		case enums.country.CZ:
			schemaUpdate.PemailSubjectCz.visible = true;
			schemaUpdate.PemailTextCz.visible = true;
			break;
		case enums.country.PL:
			schemaUpdate.PemailSubjectPl.visible = true;
			schemaUpdate.PemailTextPl.visible = true;
			break;
		case enums.country.EN:
			schemaUpdate.PemailSubjectEn.visible = true;
			schemaUpdate.PemailTextEn.visible = true;
			break;
		case enums.country.DE:
			schemaUpdate.PemailSubjectDe.visible = true;
			schemaUpdate.PemailTextDe.visible = true;
			break;
		case enums.country.HU:
			schemaUpdate.PemailSubjectHu.visible = true;
			schemaUpdate.PemailTextHu.visible = true;
			break;
		case enums.country.RU:
			schemaUpdate.PemailSubjectRu.visible = true;
			schemaUpdate.PemailTextRu.visible = true;
			break;
		case enums.country.SK:
		default:
			schemaUpdate.PemailSubjectSk.visible = true;
			schemaUpdate.PemailTextSk.visible = true;
			break;
	}

	return { schemaUpdate };
}

export function getEmailPlaceholders() {
	return [
		[
			{ id: '~contact.salutation~', value: i18n.t('oslovenie') },
			{ id: '~contact.salutationEmail~', value: i18n.t('oslovenie pre email') },
			{ id: '~contact.name~', value: i18n.t('meno') },
			{ id: '~contact.surname~', value: i18n.t('priezvisko') },
			{ id: '~contact.surnameEmail~', value: i18n.t('priezvisko pre email') },
			{ id: '', value: i18n.t('') },
		],
		[
			{ id: '~object.id~', value: i18n.t('id objektu') },
			{ id: '~object.name~', value: i18n.t('názov objektu') },
			{ id: '~object.contactperson~', value: i18n.t('kontaktná osoba objektu') },
			{ id: '~accommodationUnits~', value: i18n.t('ubytovacie jednotky') },
			{ id: '~object.previewlink~', value: i18n.t('link na objekt') },
			{ id: '~object.expiredate~', value: i18n.t('platnosť objektu do') },
			{ id: '~user.login~', value: i18n.t('prihlasovací email') },
			{ id: '~login.link~', value: i18n.t('link na prihlásenie') },
			{ id: '~user.id~', value: i18n.t('id membra/ubytovateľa') },
		],
		[
			{ id: '~invoice.id~', value: i18n.t('id faktúry') },
			{ id: '~invoice.number~', value: i18n.t('číslo faktury') },
			{ id: '~invoice.vs~', value: i18n.t('vs faktúry') },
			{ id: '~invoice.pricetotal~', value: i18n.t('suma faktúry') },
			{ id: '~invoice.maturity~', value: i18n.t('dátum splatnosti') },
			{ id: '~invoice.viewlink~', value: i18n.t('link na faktúru') },
		],
		[
			{ id: '~company.bankaccount~', value: i18n.t('číslo účtu') },
			{ id: '~company.IBAN~', value: i18n.t('iban') },
			{ id: '~company.bankcode~', value: i18n.t('kód banky') },
			{ id: '~company.bankname~', value: i18n.t('názov banky') },
			{ id: '~product.newvariablesymbol.11~', value: i18n.t('generátor VS') },
			{ id: '~product.newvariablesymbol.22~', value: i18n.t('generátor VS') },
			{ id: '~product.newvariablesymbol.33~', value: i18n.t('generátor VS') },
			{ id: '~product.newvariablesymbol.44~', value: i18n.t('generátor VS') },
			{ id: '~product.price~', value: i18n.t('cena balíka/produktu') },
		],
		[
			{ id: '~company.signature.admin~', value: i18n.t('podpis admin') },
			{ id: '~company.signature.team~', value: i18n.t('podpis team') },
			{ id: '~company.signature.operator~', value: i18n.t('podpis operátor') },
			{ id: '', value: i18n.t('') },
		],
		[
			{ id: '~currentuser.name~', value: i18n.t('meno prihláseného používateľa') },
			{ id: '~currentuser.phone~', value: i18n.t('telefón prihláseného používateľa') },
		],
		[
			{ id: '~contact.id~', value: i18n.t('id kontaktu') },
			{ id: '~registration.link~', value: i18n.t('registračný link') },
		],
		[
			{ id: '~company.sitedomain~', value: i18n.t('doména') },
			{ id: '~company.sitename~', value: i18n.t('názov stránky') },
			{ id: '~company.siteemail~', value: i18n.t('email firma') },
			{ id: '~company.phone~', value: i18n.t('telefón firma') },
		],
		[
			{ id: '~review.confirmlink~', value: i18n.t('link na potvrdenie hodnotenia') },
			{ id: '~review.evaluator~', value: i18n.t('hodnotiteľ') },
			{ id: '~review.positives~', value: i18n.t('pozitíva') },
			{ id: '~review.negatives~', value: i18n.t('negatíva') },
			{ id: '~review.averagerating~', value: i18n.t('priemerná hodnota parametrov pre dané hodnotenie') },
			{ id: '~review.autologinlink~', value: i18n.t('augologinlink s presmerovaním na hodnotenia') },
		],
		[
			{ id: '~descriptionchange.message~', value: i18n.t('znenie správy v požiadavke na zmenu popisu') },
			{ id: '~descriptionchange.redirectlink~', value: i18n.t('link na presmerovanie do popisu objektu') },
		],
		[
			{ id: '~demand.region~', value: i18n.t('lokalita') },
			{ id: '~demand.adults~', value: i18n.t('počet dospelých') },
			{ id: '~demand.children~', value: i18n.t('počet detí') },
			{ id: '~demand.datefrom~', value: i18n.t('dátum od') },
			{ id: '~demand.dateto~', value: i18n.t('dátum do') },
			{ id: '~demand.objecttypes~', value: i18n.t('typ ubytovania') },
			{ id: '~demand.note~', value: i18n.t('poznámka') },
			{ id: '~demand.pricecategory~', value: i18n.t('cena v rozmedzí') },
			{ id: '~demand.food~', value: i18n.t('stravovanie') },
			{ id: '~demand.internet~', value: i18n.t('internet ') },
			{ id: '~demand.singlegroup~', value: i18n.t('celý objekt iba pre nás') },
			{ id: '~demand.bedrooms~', value: i18n.t('min. počet spálni') },
			{ id: '~demand.bathrooms~', value: i18n.t('min. počet kúpeľní') },
			{ id: '~demand.kitchen~', value: i18n.t('min. počet kuchýň') },
			{ id: '~demand.ratings~', value: i18n.t('hodnotenia') },
			{ id: '~demand.roomequipment~', value: i18n.t('vybavenie spalní') },
			{ id: '~demand.socialroom~', value: i18n.t('vybavenie spoločenskej miestnosti') },
			{ id: '~demand.wellness~', value: i18n.t('možnosti wellness') },
			{ id: '~demand.nearobject~', value: i18n.t('pri objekte') },
			{ id: '~demand.sportfacilities~', value: i18n.t('športové možnosti') },
			{ id: '~demand.childrenfacilities~', value: i18n.t('možnosti pre deti') },
			{ id: '~demand.suitableforcongress~', value: i18n.t('pre firmy') },
			{ id: '~demand.petrace~', value: i18n.t('domáce zviera') },
			{ id: '~demand.barierfree~', value: i18n.t('bezbarierovosť') },
			{ id: '~demand.havefence~', value: i18n.t('objekt je oplotený') },
			{ id: '~demand.location1~', value: i18n.t('poloha') },
			{ id: '~demand.suitableforpeople~', value: i18n.t('ubytovanie vhodné pre') },
			{ id: '~demand.suitableforactivity~', value: i18n.t('ubytovanie vhodné na') },
			{ id: '~demand.socialpossibilities~', value: i18n.t('spoločenské priestory') },
		],
		[
			{ id: '~demand.previewlink~', value: i18n.t('link na detail inzerátu') },
			{ id: '~demand.name~', value: i18n.t('meno z inzerátu') },
			{ id: '~demand.email~', value: i18n.t('email z inzerátu') },
			{ id: '~demand.phone~', value: i18n.t('telefón z inzerátu') },
			{ id: '', value: i18n.t('') },
		],
		[
			{ id: '~reservationform.name~', value: i18n.t('meno rezervujúceho') },
			{ id: '~reservationform.email~', value: i18n.t('email rezervujúceho') },
			{ id: '~reservationform.phone~', value: i18n.t('telefón rezervujúceho') },
			{ id: '~reservationform.adults~', value: i18n.t('počet dospelých') },
			{ id: '~reservationform.children~', value: i18n.t('počet detí') },
			{ id: '~reservationform.childrenAge~', value: i18n.t('vek detí') },
			{ id: '~reservationform.datefrom~', value: i18n.t('rezervácia od') },
			{ id: '~reservationform.dateto~', value: i18n.t('rezervácia do') },
			{ id: '~reservationform.message~', value: i18n.t('správa') },
		],
		[
			{ id: '~reservation.dates~', value: i18n.t('termín pobytu vo feedbackovom emaili ubytovateľovi') },
			{ id: '~reservation.email~', value: i18n.t('email rezervácie vo feedbackovom emaili ubytovateľovi') },
		],
		[
			{ id: '~questionform.name~', value: i18n.t('meno rezervujúceho') },
			{ id: '~questionform.email~', value: i18n.t('email rezervujúceho') },
			{ id: '~questionform.phone~', value: i18n.t('telefón rezervujúceho') },
			{ id: '~questionform.message~', value: i18n.t('správa') },
			{ id: '~questionform.datefrom~', value: i18n.t('rezervácia od') },
			{ id: '~questionform.dateto~', value: i18n.t('rezervácia do') },
		],
		[
			{ id: '~unsubscribe.visitorlink~', value: i18n.t('link na odhlásenie z mailing listu pre návštevníka') },
			{
				id: '~unsubscribe.memberlink~',
				value: i18n.t('link na odhlásenie z mailing listu pre člena alebo potenciálneho člena'),
			},
			{
				id: '~unsubscribe.memberlinkImportantNews~',
				value: i18n.t('link na odhlásenie z mailing listu pre člena - odber dôležitých noviniek'),
			},
		],
		[
			{ id: '~backlink.htmlcode~', value: i18n.t('vložiť html kód ako html') },
			{ id: '~backlink.htmlcode.text~', value: i18n.t('vložiť html kód ako text') },
		],
		[{ id: '~reservationfeedback.table~', value: i18n.t('tabuľka rezervacii s linkami na feedback') }],

		[
			{ id: '~object.link.gallery~', value: i18n.t('link na otvorenú fotogalériu') },
			{ id: '~object.link.description~', value: i18n.t('link na sekciu Popis') },
			{ id: '~object.link.rooms~', value: i18n.t('link na sekciu Izby') },
			{ id: '~object.link.pricelist~', value: i18n.t('link na sekciu Ceny') },
			{ id: '~object.link.occupancy~', value: i18n.t('link na sekciu Obsadenosť') },
			{ id: '~object.link.equipment~', value: i18n.t('link na sekciu Vybavenie') },
			{ id: '~object.link.ratings~', value: i18n.t('link na sekciu Hodnotenia') },
			{ id: '~object.link.map~', value: i18n.t('link na sekciu Mapa') },
			{ id: '~object.link.nearAttractions~', value: i18n.t('link na sekciu Najbližšie atrakcie v okolí') },
			{ id: '~object.link.nearObjects~', value: i18n.t('link na sekciu Najbližšie objekty v okolí') },
			{ id: '~object.link.contactOwner.dialog~', value: i18n.t('link na otvorený oranžový rezervačný form') },
			{ id: '~object.link.contactOwnerPhone.dialog~', value: i18n.t('link na otvorený zelený kontaktný form') },
			{ id: '~object.link.ratings.dialog~', value: i18n.t('link na otvorený hodnotiaci form') },
		],
		[],
		[
			{ id: '~user.autologinlink.generalInfo~', value: i18n.t('link na Edit form Základné info') },
			{ id: '~user.autologinlink.rooms~', value: i18n.t('link na Edit form Izby') },
			{ id: '~user.autologinlink.equipment~', value: i18n.t('link na Edit form Vybavenie') },
			{ id: '~user.autologinlink.contacts~', value: i18n.t('link na Edit form Kontakty') },
			{ id: '~user.autologinlink.location~', value: i18n.t('link na Edit form Poloha') },
			{ id: '~user.autologinlink.description~', value: i18n.t('link na Edit form Popis') },
			{ id: '~user.autologinlink.pricelist~', value: i18n.t('link na Edit form Ceny') },
			{ id: '~user.autologinlink.gallery~', value: i18n.t('link na Edit form Fotografie') },
			{ id: '~user.autologinlink.occupancy~', value: i18n.t('link na Edit form Obsadenosť') },
			{ id: '~user.autologinlink.connectToWeb~', value: i18n.t('link na Edit form Prepojenia na web') },
			{ id: '~user.autologinlink.connectToWeb.ratings~', value: i18n.t('link na otvorený iFrame Hodnotenia') },
			{
				id: '~user.autologinlink.connectToWeb.ratingButton~',
				value: i18n.t('link na otvorený iFrame Tlačidlo na zaslanie hodnotenia'),
			},
			{
				id: '~user.autologinlink.connectToWeb.reservationButton~',
				value: i18n.t('link na otvorený iFrame Tlačidlo na zaslanie rezervácie'),
			},
			{ id: '~user.autologinlink.connectToWeb.occupancy~', value: i18n.t('link na otvorený iFrame Kalendár obsadenosti') },
			{ id: '~user.autologinlink.connectToWeb.reservation~', value: i18n.t('link na otvorený iFrame Rezervácia') },
		],
	];
}
