<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="pmanualforemail"
		:showActionDelete="showDelete"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofemail') }}
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.PemailId }}</label>
		</template>

		<template #customActions>
			<megau-button
				classprop="btn btn-success"
				v-show="showDuplicate"
				icon="check"
				:title="$t('duplicateemail')"
				@handleClick="duplicate()"
			></megau-button>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '@/services/service/dictionary.service';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '@/services/service/common.service';
import enums from '@/services/helpers/enums';
import auth from '@/services/auth.service';
import apiCommon from '@/services/api/common.api';
import { model, fields } from './emails-templates.form-data';
import { handleTextboxVisibility, getEmailPlaceholders } from './emails-templates.service';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'PortalEmail',
			routeParam: 'PemailId',
			model,
			schema: {
				groups: fields,
			},
			technicalEmailTypeId: 1,
		};
	},

	computed: {
		showDelete() {
			return (
				auth.IsAllowedForUser(enums.role.Admin) && this.model.PemailType !== this.technicalEmailTypeId && !!this.detailEntityId
			);
		},

		showDuplicate() {
			return !!this.detailEntityId;
		},
	},

	mounted() {
		const toUpdate = handleTextboxVisibility(this.model.TextSelector, this.model);
		this.updateSchemaFields(toUpdate.schemaUpdate);
	},

	methods: {
		async loadModel(id = null) {
			const value = id || this.detailEntityId;
			const resp = await apiCommon.getEntity(value, this.controllerName);
			const model = this.transformDtoToModel(resp.data);
			const toUpdate = await handleTextboxVisibility(model.CountryId, model);
			this.updateSchemaFields(toUpdate.schemaUpdate);
			this.updateFormModel({ ...model, ...toUpdate.modelUpdate }, true);
		},

		async loadResources() {
			this.updateSchemaFields({
				TextSelector: {
					values: this.$store.state.country.data,
					onChanged: (model, newVal, oldVal, field) => {
						const toUpdate = handleTextboxVisibility(newVal, model);
						this.updateSchemaFields(toUpdate.schemaUpdate);
					},
				},
				PemailType: {
					values: serviceEnums.getEnumForVFG('emailType'),
				},
				Placeholders: {
					items: getEmailPlaceholders(),
				},
			});
		},

		transformDtoToModel(dtoModel) {
			return {
				...dtoModel,
				TextSelector: dtoModel.CountryId || enums.country.SK,
			};
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.PemailId, this.$modal, this.deleteModel);
		},

		duplicate() {
			this.redirectToDetail(0, {
				...this.model,
				PemailId: 0,
				emailIsValid: false,
				PemailName: this.model.PemailName + ' - ' + this.$i18n.t('(kopia)'),
			});
		},
	},
};
</script>

<style lang="scss">
.emails-form {
	.field-html-text-editor {
		.ck-content {
			width: 955px !important;
		}
		.ck-sticky-panel__content_sticky,
		.ck-sticky-panel__content {
			width: 955px !important;
		}
	}
}
</style>
