import vueFormGenerator from 'vue-form-generator';
import enums from '@/services/helpers/enums';

export const model = {
	PemailId: 0,
	PemailName: null,
	PemailLanguage: 'SK',
	PemailType: null,
	PemailIsValid: false,
	CountryId: 1,
	Conversions: null,
	TextSelector: enums.country.SK,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailName',
				min: 1,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'emailtype',
				},
			},
			{
				type: 'select',
				model: 'PemailType',
				required: true,
				values: [],
				validator: vueFormGenerator.validators.integer,
				selectOptions: {},
				i18n: {
					label: 'type:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'switch',
				model: 'PemailIsValid',
				i18n: {
					label: 'checked:',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'Conversions',
				min: 1,
				i18n: {
					label: 'conversion2993',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'radios',
				model: 'TextSelector',
				values: [],
				radiosOptions: {
					value: 'Id',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languagetransla',
				},
				styleClasses: 'inline-radio-list',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectSk',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectCz',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectPl',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectEn',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectDe',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectHu',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'PemailSubjectRu',
				i18n: {
					label: 'subject2994',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextSk',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextCz',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextPl',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextEn',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextDe',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextHu',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'PemailTextRu',
				i18n: {
					label: 'text:',
				},
				visible: false,
			},
			{
				type: 'labels',
				model: 'Placeholders',
				items: [],
				i18n: {
					label: 'placeholders',
				},
			},
		],
	},
];
